import * as $ from 'jquery';
import '@fortawesome/fontawesome-free/css/fontawesome.min.css'
import popup  from './libs/popup.js';
import 'lazyload';

window.$ = $

function burgerMenu(){
	$('header .burger').on('click', function(){
		if(!$('header .burger').parent().find('.main-header').hasClass('act')){
			$(this).addClass('collapse')
			$(this).parent().find('.main-header').addClass('act')
			$('body').addClass('lock')
		} else {
			$(this).removeClass('collapse')
			$(this).parent().find('.main-header').removeClass('act')
			$('body').removeClass('lock')
		}
	})
	
	if($(window).width() < 1023){
		$('header .have-child > a').on('click', function(){
			window.location.hash = '';
			if(!$(this).parent().hasClass('drop')){
				$(this).parent().addClass('drop');
				$(this).parent().children('ul').slideDown()
			} else {
				$(this).parent().removeClass('drop');
				$(this).parent().children('ul').slideUp()
			}
		})
		$('header .menu > ul > li > a').on('click', function(){
			setTimeout(function(){
				if(window.location.hash !== ""){
					$('header .burger').removeClass('collapse')
					$('header .burger').parent().find('.main-header').removeClass('act')
					$('body').removeClass('lock')
				}
			}, 300)
			
		})
	}
	
}

function userDrop(){
	$('header .user .ico').on('click', function(){
		if(!$(this).parent().hasClass('drop')){
			$(this).parent().addClass('drop')
		} else {
			$(this).parent().removeClass('drop')
			
		}
	})

	$(document).bind('click', function(e) {
		var $clicked = $(e.target);
		if (! $clicked.parents().hasClass("user")) {
			$('header .user').removeClass("drop");                
		}
	});
}

function animateDivers() {
	$('.float').animate(
		{'bottom':'60px'}
		,1000
	)
	.animate(
		{'bottom':'30px'}
		,1000
		,animateDivers //callback the function, to restart animation cycle
	); 
}


$(document).ready(() => {
	let images = $(".lazyload");
  	lazyload(images);
	popup($).closeGeneral()
	burgerMenu()
	userDrop()
	$('.float').addClass('show');
	setTimeout(animateDivers, 3000)
	//animateDivers();
	
})

window.openPopup = (id) => {
	popup($).openPopup(id)
}
window.closePopup = (id) => {
	popup($).openPopup(id)
}